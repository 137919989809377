.select-studies-table-container {
    margin: 0 auto;
}

.select-studies-table {
    font-size: 16px;
    text-align: left;
    margin: 0 auto;
    border-collapse: collapse;
}

.select-studies-table th, .select-studies-table td {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 20px;
}

.select-studies-table-header th {
    border-bottom: 1px solid white;
}

tr.select-studies-table-header {
    /* border-bottom: 1px solid white; */
    color: var(--text-primary)
}

.select-studies-remove-series-tooltip {
    max-width: 400px;
}

.unsupported-files {
    background-color: var(--background-grey-blue-light);
    color: var(--text-black);
    max-width: 500px;
    font-size: 16px;
    display:inline-block;
    padding: 5px;
    border-radius: 4px;
}

.select-studies-table td {
    color: var(--text-primary)
}

td.select-studies-delete {
    color: red;
    cursor: pointer;
}

.select-studies-removed-series-tooltip-icon {
    color: white;
    padding: 0px 10px;
    width: 16px;
}

.select-studies-delete:hover {
    color: var(--red-highlight)
}

.select-studies-warning {
    margin: 5px auto;
    max-width: 500px;
    border-radius: 6px;
    padding: 10px;
    color: var(--text-primary);
    background-color: var(--background-warning);
}

.order-studies-warning {
    margin: 20px auto;
    max-width: 600px;
    border-radius: 6px;
    padding: 10px;
    color: var(--text-primary);
    background-color: var(--background-warning);
}

.select-studies-date {
    width: 110px;
}

.select-studies-date-input {
    height: 20px;
}